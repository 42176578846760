<!-- src/views/Home.vue -->
<template>
  <el-menu
    class="el-menu-demo"
    mode="horizontal"
    :ellipsis="false"
    background-color="var(--background-color)"
    text-color="var(--text-color)"
    active-text-color="var(--text-color)"
    @select="handleSelect"
  >
    <el-menu-item index="/" class="logo-item">
      <el-avatar :src="logo" :size="58" :alt="title" />
      <el-text class="mx-1" tag="b" :size="24"> {{ title }}</el-text>
    </el-menu-item>

    <!-- <el-menu-item
      v-for="item in menuList"
      :index="item.path"
      :key="item.path"
      >{{ item.name }}</el-menu-item
    > -->
  </el-menu>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";

import logo from "@/assets/logo.png";
const title = ref("memefun");

const router = useRouter();
const handleSelect = (key: string, keyPath: string[]) => {
  console.log(key, keyPath);
  router.push(key);
};

// const menuList = [
//   {
//     name: "Home",
//     path: "/home",
//   },
//   {
//     name: "Features",
//     path: "/features",
//   },
//   {
//     name: "Token",
//     path: "/token",
//   },
// ];
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 991px) {
  .el-menu {
    font-size: 14px; // 调整字体大小适应手机屏幕
    padding: 10px; // 增加内边距
  }

  .el-menu-item {
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 40px; // 设定菜单项高度
    line-height: 40px; // 垂直居中文字
  }

  .el-menu-item:first-child {
    width: 100%;
  }
}

@media only screen and (min-width: 769px) {
  .el-menu-item {
    overflow: visible;
    text-overflow: clip;
    white-space: normal;
  }
}

.el-menu-demo {
  border-bottom: 0;
  display: flex;
  justify-content: space-between;
  margin: 15px;
}
.el-menu-demo .el-menu-item {
  text-decoration: none;
}
.el-menu-demo :first-child {
  margin-right: auto;
}

.logo-item .el-text {
  margin: 5px 10px;
  color: #fff;
  font-weight: 900;
  font-size: 18px;
  line-height: 23px;
  text-align: left;
  font-style: normal;
}

// 添加悬停样式
.el-menu-item:hover {
  background-color: rgba(255, 255, 255, 0.1); // 悬停时的背景色
  color: #fff; // 悬停时的文字颜色
}

// 为 logo-item 添加悬停样式
.logo-item:hover {
  background-color: transparent; // 保持 logo-item 的背景透明
}

// 为 logo-item 内的元素添加悬停样式
.logo-item:hover .el-avatar,
.logo-item:hover .el-text {
  color: #fff; // 保持 logo-item 内元素的颜色不变
}
</style>
