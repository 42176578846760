// src/router/index.ts
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import MainLayout from '@/views/MainLayout.vue';
import Home from '@/views/Home.vue';
import Features from '@/views/Features.vue';
import Token from '@/views/Token.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home,
      },
      {
        path: 'features',
        name: 'Features',
        component: Features,
      },
      {
        path: 'token',
        name: 'Token',
        component: Token,
      },
      // 添加重定向规则
      {
        path: '/home',
        redirect: '/',
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// 添加路由的导航守卫
router.beforeEach((to, from, next) => {
  console.log('Navigating to:', to.path);
  console.log('Navigating from:', from.path);

  // 示例：检查用户是否登录
  const isLoggedIn = false; // 假设这里是从某个地方获取的登录状态
  if (to.path === '/login' && !isLoggedIn) {
    alert('您需要先登录才能访问此页面！');
    next('/'); // 重定向到首页
  } else {
    next();
  }
});

export default router;