<template>
  <el-config-provider :size="sizeValue" :z-index="zIndexValue">
    <router-view />
  </el-config-provider>
</template>

<script setup lang="ts">
import { inject } from "vue";

const sizeValue = inject("size");
const zIndexValue = inject("zIndex");
</script>
<style lang="scss" scoped></style>
