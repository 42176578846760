<template>
  <div class="session session2">
    <div class="section-font">
      <h1>why memefun</h1>
      <ul>
        <li>
          Token creators enjoys portion of trading fee to incentivize
          high-profile creators
        </li>
        <li>
          Token holders enjoyer platform revenues and vote for proposal
          accordingly
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
<style lang="scss" scoped>
.session2 {
  position: relative;
  background-color: var(--background-2-color);
  color: var(--text-2-color);
  min-height: 50vh;

  display: flex;
  justify-content: center;
  align-items: center;

  .section-font {
    width: 33vw;
    margin: 5rem auto;
    h1 {
      font-weight: bold;
      font-size: 2.5rem;
      line-height: 3.13rem;
      text-align: center;
      margin-bottom: 2.5rem;
    }

    li {
      font-weight: 300;
      font-size: 1.5rem;
    }

    li::before {
      content: "•";
      color: #000; /* 设置小圆点的颜色为白色 */
      display: inline-block;
      margin-right: 10px;
      padding-inline-start: -20px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .session2 {
    .section-font {
      width: 90vw;
      margin: 5rem auto;
      h1 {
        font-weight: bold;
        font-size: 2.5rem;
        line-height: 3.13rem;
        text-align: center;
        margin-bottom: 2.5rem;
        overflow: hidden;
      }

      li {
        font-weight: 300;
        font-size: 1.5rem;
      }

      li::before {
        content: "•";
        color: #000; /* 设置小圆点的颜色为白色 */
        display: inline-block;
        margin-right: 10px;
        padding-inline-start: -20px;
      }
    }
  }
}
</style>
