<!-- src/views/Home.vue -->
<template>
  <div class="session session1">
    <el-row>
      <el-col :sm="24" :md="12" class="section-img">
        <el-image
          :src="sectionImg1"
          :key="sectionImg1"
          fit="contain"
          lazy
        ></el-image>
      </el-col>
      <el-col :sm="24" :md="12" class="section-font">
        <h1>MeMefun</h1>
        <p>
          Empowering Memes, Enriching Communities, Share the Wealth with
          memefun.me
        </p>
        <span>
          Since pumpfun launch 5 months ago, Pumpfun has generated over $90
          million in revenue, with over $5 million earned on a single day,
          August 13. All of this revenue has gone to the team. We hope to
          redistribute these substantial earnings to the community.
        </span>
        <div class="contact-icon">
          <ContactComponent />
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted } from "vue";

import sectionImg1 from "@/assets/bg.png";
import ContactComponent from "@/components/ContactComponent.vue";

interface ImageItem {
  src: string;
  url: string;
}

const imgList = ref<ImageItem[]>([]);

async function loadImages() {
  const [iconX, iconT, iconM] = await Promise.all([
    import("@/assets/icon_X.png"),
    import("@/assets/icon_T.png"),
    import("@/assets/icon_M.png"),
  ]);

  imgList.value = [
    { src: (await iconX).default, url: "https://x.com/memefunme" },
    { src: (await iconT).default, url: "https://t.me/+RsjVaSXaL-g4MTA1" },
    { src: (await iconM).default, url: "https://memefunme.medium.com" },
  ];
}

onMounted(() => {
  loadImages();
});
</script>
<style lang="scss" scoped>
.session1 {
  background-color: var(--background-color);
  color: var(--text-color);
  height: calc(100vh - 96px);
}
.section-img {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .el-image {
    width: 40.88rem;
    height: 40.88rem;
    margin: 6.06rem auto;
  }
}

.section-font {
  padding-left: 3rem;
  padding-right: 8rem;

  h1 {
    font-size: calc(5.63rem - 0.5vw);
    max-width: 26.31rem;
    font-weight: 900;
    line-height: 7.06rem;
    text-align: center;
  }
  p {
    margin-top: -4rem;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.88rem;
    max-width: 33.69rem;
    width: 80%;
  }
  span {
    display: block;
    max-width: 36.81rem;
    width: 90%;
    font-weight: 200;
    font-size: 1.2rem;
    line-height: 2rem;
    margin-top: 6.19rem;
  }

  .contact-icon {
    margin-top: 2.44rem;
    float: right;

    .link-container {
      gap: 1rem; // 链接之间的间距
    }
  }
}

@media only screen and (max-width: 991px) {
  .session1 {
    min-height: 100vh;
    height: auto;
  }

  .section-img {
    height: calc(90vw + 2rem);

    .el-image {
      width: 90vw;
      height: auto;
      margin: 5vw 1rem;
    }
  }

  .section-font {
    padding: 0 5vw;
    width: 90vw;

    h1 {
      max-width: 100%;
      font-size: 4rem;
      text-align: center;
      margin-top: -1rem;
    }
    p {
      max-width: 100%;
      margin-top: -4rem;
      font-weight: 400;
      font-size: 1.13rem;
      line-height: 1.88rem;
      width: 90vw;
    }
    span {
      max-width: 100%;
      display: block;
      font-weight: 200;
      font-size: 1.13rem;
      margin-top: 3rem;
      width: 90vw;
    }

    .contact-icon {
      padding: 2.44rem 5vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .link-container {
        gap: 2rem; // 链接之间的间距
      }
    }
  }
}
</style>
