<template>
  <div class="session session4">
    <div class="session-font">
      <h1>Platform fees</h1>
      <ul>
        <li>
          1%
          <span>Trading fee</span>
        </li>
        <li>
          $ 2 charged by first buyer
          <span>Token creation fee</span>
        </li>
        <li>
          1.5 SOl, 0.5 token creator
          <span>Migration SOL returns to fee </span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup lang="ts"></script>

<style lang="scss" scoped>
.session4 {
  background-color: var(--background-2-color);
  color: var(--text-2-color);
  min-height: 50vh;

  .session-font {
    width: 30vw;
    margin: 5rem auto;
    h1 {
      font-weight: bold;
      font-size: 2.5rem;
      line-height: 3.13rem;
      text-align: center;
      margin-bottom: 2.5rem;
      overflow: hidden;
    }
    li {
      font-weight: 300;
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }

    li span {
      font-weight: 300;
      display: block;
      margin-left: 4ch; /* 2 characters indent */
      font-size: 12px;
    }

    li::before {
      content: "•";
      color: var(--text-2-color); /* 设置小圆点的颜色为白色 */
      display: inline-block;
      margin-right: 10px;
      padding-inline-start: -20px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .session4 {
    .session-font {
      width: 90vw;
    }
  }
}
</style>
